import styled, { createGlobalStyle, css } from 'styled-components'
import { darken, lighten } from 'polished'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html,
  body {
    scroll-padding-top: 140px;
    margin: 0;
    overscroll-behavior: none;
    background-color: ${(props) => props.theme.cream};
    color: ${(props) => props.theme.textRegular};
    font-family: 'FilsonProBook';
    font-size: 16px;
    font-weight: 300;
    line-height: 1.625;
    /* remove margin for the main div that Gatsby mounts into */
    > div {
      margin-top: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'P22MackinacProBook';
    font-weight: 300;
    color: ${(props) => props.theme.textDarker};
    line-height: 1.25;
    + * {
      margin-top: 0.5rem;
    }
  }
  a {
    font-family: 'FilsonPro';
  }
  strong {
    color: ${(props) => props.theme.textDarker};
  }
  li {
    margin-top: 0.25rem;
  }
  input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
`

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const ContentContainer = styled.section`
  margin: 2rem auto 4rem;
  width: 1200px;
  max-width: 90vw;
`

export const BigHeader = styled.h1`
  font-family: 'P22MackinacProBook';
  text-align: left;
  margin: 50px;
  font-size: 38px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 1.5em;
  }
`
export const MediumHeader = styled.h3`
  font-family: 'P22MackinacProBook';
  text-align: center;
  font-weight: 400;
  margin: 0 0 60px 0;
`

export const MediumText = styled.p`
  font-size: 20px;
`

export const SmallText = styled.p`
  font-size: 16px;
`

export const Hr = styled.hr``

export const Button = styled.button`
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  padding: 10px 20px;
  line-height: 1.625;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.2, props.color)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.3, props.color)}`};
  }
`

export const ButtonCss = css`
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  padding: 10px 20px;
  line-height: 1.625;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.2, props.color)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.3, props.color)}`};
  }
`

export const CenteredContent = styled.p`
  text-align: center;
`

export const TwoColumnGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2rem 1fr;
  grid-template-columns: 1fr 2rem 1fr;
  -ms-grid-rows: 1 auto;
  grid-template-rows: 1 auto;
  grid-template-areas: 'left . right';
  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
  grid-column: 1 / 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
`

export const GridRight = styled.div`
  grid-area: right;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
`

export const HiddenOnDesktop = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const HiddenOnMobile = styled.div`
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const Img = styled(GatsbyImage)`
  max-width: 100%;
  margin: 0;
  padding: 0;
`

export const contentPadding = css`
  padding: 1.4rem 5vw;
  @media (min-width: calc(1200px + 10vw)) and (max-width: 100%) {
    padding-left: calc((100vw - 1200px - 1rem) / 2);
    padding-right: calc((100vw - 1200px - 1rem) / 2);
  }
`

export const ColoredText = styled.span`
  color: ${(props) => props.color};
  display: ${(props) => props.display || 'inline'};
  @media (max-width: ${breakpoints.l}px) {
    display: ${(props) => (props.hideOnMobile ? 'none' : props.display)};
  }
`
export const CustomizedContentContainer = styled.section`
  margin: 2rem auto 0rem;
  width: 1200px;
  max-width: 90vw;
`
export const BuyNowLinkCSS = css`
  font-family: 'FilsonPro';
  background-color: ${(props) => props.theme.coral};
  color: white;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  font-weight: normal;
  padding: 15px 30px;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    letter-spacing: 0.08px;
  }
`

export const BuyNowLink = styled(Link)`
  ${BuyNowLinkCSS}
`
export const Header = styled.h2`
  color: ${(props) => props.color || props.theme.grayDark};
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.19px;
  text-align: center;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
`
export const HideOverflow = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.color || props.theme.cream};
`
