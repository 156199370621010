import ABTestGroup from 'src/utils/ABTestGroup'

const Analytics = {
  // For standard events, e.g.
  // // dataLayer.push({ event: 'Purchased Frame' })
  track: (event) => {
    const abTestGroup = ABTestGroup.current()
    // console.log(`Logging ${event} from ${abTestGroup}`)
    try {
      window.dataLayer = window.dataLayer || []
      dataLayer.push({ event, abTestGroup }) // eslint-disable-line no-undef
    } catch (err) {
      console.log('Failed to track event: ', err)
    }
  },
  // For Enhanced Ecommerce events, e.g.
  // // dataLayer.push({
  // //   ecommerce: {
  // //     purchase: {
  // //       actionField: {
  // //         ...
  // //       },
  // //       products: [...],
  // //     },
  // //   },
  // // })
  push: (eventData) => {
    try {
      eventData.abTestGroup = ABTestGroup.current()
      dataLayer.push(eventData) // eslint-disable-line no-undef
    } catch (err) {
      console.log('Failed to push event: ', err)
    }
  },
  trackingConstants: {
    'skylight-plus-limited-time-50-off': 'Plus 50% off',
    'skylight-plus': 'Plus',
    'skylight-calendar': 'Calendar',
    'calendar-skylight-plus': 'Calendar Plus',
    'the-skylight-calendar': 'Calendar',
    'the-skylight-calendar-pro-149': 'Calendar',
    'the-skylight-calendar-15-with-photo-plus-plan': 'Calendar',
    'the-skylight-calendar-15': 'Calendar',
  },
}

export default Analytics
