import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import UTMParams from 'src/utils/UTMParams'
import {
  BUY_LINK,
  CAL_BUY_LINK,
  INITIALIZE_AMPLITUDE,
  SHOW_COOKIE_CONSENT_BANNER,
  IS_FOREIGN_COUNTRY,
} from 'src/utils/constants'
import { useLocation, Location } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsentBanner from '../components/CookieConsentBanner'
import ContextProvider from '../provider/ContextProvider'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import { GlobalStyles } from '../utils/styles/global-styles'
import theme from '../utils/styles/theme'
import 'normalize.css'
import BlogNavigation from '../components/BlogNavigation'
import Cookie from '../utils/GetCookie'

class Layout extends React.Component {
  componentDidMount() {
    UTMParams.save(window)
    const tracking = Cookie.get('gatsby-gdpr-google-analytics')
    if (!SHOW_COOKIE_CONSENT_BANNER && !tracking) {
      Cookie.set('gatsby-gdpr-google-analytics', true)
      Cookie.set('gatsby-gdpr-google-tagmanager', true)
      initializeAndTrack(useLocation)
    }
  }

  render() {
    const { children, pageContext } = this.props
    const { layout, fields } = pageContext
    const NAVIGATION_OVERRIDES = {
      calendar: <Navigation buyLink={CAL_BUY_LINK} page={layout} handle={pageContext.handle} />,
      basicBlog: <BlogNavigation />,
      retail: <div />,
    }
    const FOOTER_OVERRIDES = {
      basicBlog: <div />,
      retail: <div />,
    }

    let buyLink = fields?.hero?.cta_button_link || BUY_LINK

    if (typeof window !== 'undefined' && !IS_FOREIGN_COUNTRY) {
      const currentPath = window.location.pathname
      if (!fields?.hero?.cta_button_link) {
        if (currentPath.includes('frame')) {
          buyLink = BUY_LINK
        } else {
          buyLink = CAL_BUY_LINK
        }
      }
    }
    const FooterComponent = FOOTER_OVERRIDES[layout] || (
      <Footer page={layout} handle={pageContext.handle} />
    )
    const pagesToHideBanner = ['basicBlog', 'blog', 'retail']
    function urlContainsMentalLoad() {
      return typeof window !== 'undefined' && window.location.href.indexOf('mental-load') !== -1
    }
    const shouldHideForLanguage = ['de', 'it', 'es', 'se', 'de', 'fr', 'pl', 'nl'].includes(
      process.env.GATSBY_I18N_DEFAULT_LANGUAGE
    )

    const hideBanner =
      pagesToHideBanner.indexOf(layout) !== -1 || urlContainsMentalLoad() || shouldHideForLanguage
    return (
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <Helmet
            script={[
              {
                type: 'text/javascript',
                innerHTML: INITIALIZE_AMPLITUDE,
              },
            ]}
          />
          <GlobalStyles />
          <Header>
            <Location>
              {({ location }) => {
                const isHomepage = location.pathname === '/'
                const buyAnalyticsEvent = isHomepage
                  ? 'Clicked buy now on Homepage NAV CTA'
                  : 'Clicked buy now in NAV CTA'
                const NavComponent = NAVIGATION_OVERRIDES[layout] || (
                  <Navigation
                    buyLink={buyLink}
                    buyAnalyticsEvent={buyAnalyticsEvent}
                    page={layout}
                    handle={pageContext.handle}
                    supportLink="https://skylight.zendesk.com/"
                  />
                )
                return (
                  <>
                    {!hideBanner && <Banner page={layout} />}
                    {NavComponent}
                  </>
                )
              }}
            </Location>
          </Header>
          <main>{children}</main>
          {FooterComponent}
          {SHOW_COOKIE_CONSENT_BANNER && <CookieConsentBanner />}
        </ThemeProvider>
      </ContextProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.any,
}
export default Layout

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 40;
`
